import React from "react";
import { Link } from "react-router-dom";
import "../styles/NotFound.css"; // CSS for styling

const NotFound = () => {
    return (
        <div className="not-found-container">
            <div className="not-found-content">
                <h1>404</h1>
                <p>Oops! The page you're looking for doesn't exist.</p>
                <Link to="/">
                    <button className="home-button">Go to Homepage</button>
                </Link>
            </div>
        </div>
    );
};

export default NotFound;