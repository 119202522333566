import React, { useEffect } from "react";
import "../styles/FeaturesPage.css";
import {
    FaUsers,
    FaEdit,
    FaChartLine,
    FaMagic,
    FaImage,
    FaMicrophone,
    FaCalendarAlt,
    FaHandsHelping,
    FaRobot,
    FaLightbulb,
    FaSyncAlt,
    FaProjectDiagram,
    FaSearch,
    FaCloudUploadAlt,
    FaBolt,
    FaPaintBrush
} from "react-icons/fa";
import Aos from "aos";
import "aos/dist/aos.css";

const features = [
    {
        icon: <FaUsers />,
        title: "Manage Multiple Brands",
        description:
            "Easily manage multiple brands with tailored voices, target audiences, and strategies. Build unique brand identities that resonate with your audience.",
    },
    {
        icon: <FaEdit />,
        title: "Effortless Content Creation",
        description:
            "Generate high-quality blogs, emails, and social media posts in seconds. Focus on creativity while our tools handle the rest.",
    },
    {
        icon: <FaChartLine />,
        title: "AI-Driven Campaigns",
        description:
            "Optimize your marketing efforts with actionable insights and recommendations to boost engagement and performance.",
    },
    {
        icon: <FaMagic />,
        title: "Step-by-Step Journeys",
        description:
            "Follow guided workflows for lead generation, sales, and conversions. Achieve your goals faster with AI-driven processes.",
    },
    {
        icon: <FaImage />,
        title: "AI Image Generation",
        description:
            "Create professional visuals in seconds with AI-powered image generation. Elevate your campaigns and social media effortlessly.",
    },
    {
        icon: <FaMicrophone />,
        title: "Text-to-Speech",
        description:
            "Transform text into natural, engaging speech to make your content more accessible and engaging.",
    },
    {
        icon: <FaCalendarAlt />,
        title: "Campaign Calendar",
        description:
            "Plan, schedule, and monitor your campaigns with an intuitive calendar tool. Never miss a deadline.",
    },
    {
        icon: <FaHandsHelping />,
        title: "Seamless Collaboration",
        description:
            "Collaborate in real-time with your team by assigning and editing content pieces effortlessly.",
    },
    {
        icon: <FaRobot />,
        title: "AI Chat Personal Assistant",
        description:
            "Get instant answers, insights, and suggestions from your AI-powered assistant. Always available to help.",
    },
    {
        icon: <FaLightbulb />,
        title: "Content Ideas Generation",
        description:
            "Generate fresh, tailored content ideas that resonate with your audience. Save hours of brainstorming and research.",
    },
    {
        icon: <FaSyncAlt />,
        title: "Real-Time Editing",
        description:
            "Collaborate on content in real-time with your team. Track changes and approve edits effortlessly.",
    },
    {
        icon: <FaProjectDiagram />,
        title: "Marketing Funnels",
        description:
            "Create, optimize, and analyze marketing funnels with AI-driven insights. Generate leads and conversions like never before.",
    },
    {
        icon: <FaSearch />,
        title: "SEO Optimization",
        description:
            "Improve your content's visibility with AI-driven SEO content. Optimize keywords, structure, and readability with ease.",
    },
    {
        icon: <FaCloudUploadAlt />,
        title: "Cloud-Based Storage",
        description:
            "Store and access all your campaigns, content, and assets securely in one place. Work seamlessly from anywhere.",
    },
    {
        icon: <FaBolt />,
        title: "Instant Content Templates",
        description:
            "Access 80+ professionally designed templates for blogs, ads, emails, and more. Jumpstart your campaigns with ease.",
    },
    {
        icon: <FaPaintBrush />,
        title: "Personalized Branding",
        description:
            "Tailor your content to match your brand’s unique voice, ensuring consistency and authenticity across all platforms.",
    }
];

const FeaturesPage = () => {
    useEffect(() => {
        Aos.init({ duration: 1200, once: true });
    }, []);

    return (
        <div className="features-page">
            <h1 className="features-title" data-aos="fade-down">
                Empower Your Business with Cutting-Edge Features
            </h1>
            <p className="features-intro" data-aos="fade-up">
                Transform how you create, collaborate, and grow with our comprehensive suite of AI-powered tools.
            </p>
            <div className="features-grid">
                {features.map((feature, index) => (
                    <div
                        className="feature-card"
                        data-aos="zoom-in"
                        data-aos-delay={`${index * 100}`}
                        key={index}
                    >
                        <div className="feature-icon">{feature.icon}</div>
                        <h3 className="feature-title">{feature.title}</h3>
                        <p className="feature-description">{feature.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FeaturesPage;