import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/Footer.css";
import { FaFacebookF, FaInstagram } from "react-icons/fa";

const Footer = () => {
    const navigate = useNavigate();

    return (
        <footer className="footer">
            <div className="footer-content">
                {/* Links Section */}
                <div className="footer-links">
                    <a href="/privacy" className="footer-link">
                        Privacy Policy
                    </a>
                    <a href="/terms" className="footer-link">
                        Terms and Conditions
                    </a>
                    <a href="/cookie-policy" className="footer-link">
                        Cookie Policy
                    </a>
                </div>

                {/* Social Media Section */}
                <div className="footer-social">
                    <a href="https://www.facebook.com/people/Brand-Bounce/61559754806993/" target="_blank" rel="noopener noreferrer" className="social-icon">
                        <FaFacebookF />
                    </a>
                    <a href="https://www.instagram.com/usebrandbounce/" target="_blank" rel="noopener noreferrer" className="social-icon">
                        <FaInstagram />
                    </a>
                </div>

                {/* Call to Action */}
                <div className="footer-cta">
                    <h3 className="footer-cta-title">Join the Trial</h3>
                    <p className="footer-cta-description">
                        Experience the power of AI-driven tools to simplify your workflows and grow your business.
                    </p>
                    <button className="footer-cta-button" onClick={() => navigate('/pricing')}>
                        Start Free Trial
                    </button>
                </div>

                {/* Footer Info */}
                <div className="footer-info">
                    <p>© 2025 Brand Bounce. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;