import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import ContactPage from "./components/ContactPage";
import Footer from "./layouts/Footer";
import Nav from "./layouts/Nav";
import PricingPage from "./components/PricingPage";
import FeaturesPage from "./components/FeaturesPage";
import ScrollToTop from "./utils/ScrollToTop";
import NotFound from "./components/NotFound";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsPage from "./components/TermsPage";
import CookiePolicyPage from "./components/CookiePolicyPage";
import CookieConsent from "./components/parts/CookieConsent";

class App extends Component {
  render() {
    return (
        <Router>
          <div>
            <Nav />
              <ScrollToTop />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/pricing" element={<PricingPage />} />
              <Route path="/features" element={<FeaturesPage />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<TermsPage />} />
              <Route path="/cookie-policy" element={<CookiePolicyPage />} />
              <Route path="*" element={<NotFound />} /> {/* Catch-all route */}
            </Routes>
            <CookieConsent />
            <Footer />
          </div>
        </Router>
    );
  }
}

export default App;