import React from "react";
import "../../styles/HowItWorksSection.css";
import { FaUser, FaDollarSign, FaRocket, FaPenFancy, FaLightbulb } from "react-icons/fa";

const HowItWorksSection = () => {
    const steps = [
        {
            icon: FaUser,
            title: "Sign Up in Seconds",
            description: "Create your account effortlessly and unlock the power of AI-driven tools.",
        },
        {
            icon: FaDollarSign,
            title: "Pick the Perfect Plan",
            description: "Choose a subscription that fits your needs and start building your success story.",
        },
        {
            icon: FaRocket,
            title: "Enjoy a Free Trial",
            description: "Experience the full potential of our platform with a risk-free trial.",
        },
        {
            icon: FaPenFancy,
            title: "Define Your Brand",
            description: "Set up your first brand, complete with tailored voice and audience details.",
        },
        {
            icon: FaLightbulb,
            title: "Create, Plan, and Deliver",
            description: "Start generating captivating content and ideas, all customized to align with your brand's unique identity.",
        },
    ];

    return (
        <div className="how-it-works-section">
            <h2 className="section-title">How It Works</h2>
            <p className="section-description">
                Follow these simple steps to transform your workflow with AI-powered tools.
            </p>
            <div className="steps-container">
                {steps.map((step, index) => (
                    <div className="step-wrapper" key={index}>
                        <div className="step-item" data-aos="fade-up" data-aos-delay={`${index * 200}`}>
                            <div className="step-icon">
                                <step.icon className="icon" />
                            </div>
                            <div className="step-content">
                                <h3 className="step-title">{step.title}</h3>
                                <p className="step-description">{step.description}</p>
                            </div>
                        </div>
                        {index < steps.length - 1 && (
                            <div className="step-arrow" data-aos="fade-up" data-aos-delay={`${index * 200 + 100}`}>
                                <span>↓</span> {/* Arrow connecting steps */}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HowItWorksSection;