import React from "react";
import "../styles/TermsPage.css";

const TermsPage = () => {
    return (
        <div className="terms-page">
            <h1 className="terms-title">Terms and Conditions</h1>
            <p className="terms-intro">
                Welcome to Brand Bounce! Please read these terms and conditions carefully before using our website or services.
            </p>
            <p className="effective-date"><strong>Effective Date: January 12, 2025</strong></p>

            <h2 className="terms-subtitle">1. Agreement to Terms</h2>
            <p>
                1.1 You agree that by accessing the site, <a href="https://www.brand-bounce.com">www.brand-bounce.com</a>,
                and/or services, you have read, understood, and agree to be bound by all of these Terms of Agreement.
                If you do not agree with all of these Terms of Agreement, then you are expressly prohibited from using the site,
                and you must discontinue use immediately.
            </p>

            <h2 className="terms-subtitle">2. Intellectual Property Rights</h2>
            <p>
                2.1 Unless otherwise indicated, the site and all content and other materials therein, including, without limitation,
                the Brand Bounce logo, and all designs, text, graphics, pictures, information, data, software, sound files, other files,
                and the selection and arrangement thereof are the proprietary property of Brand Bounce or our affiliates, licensors,
                or users as applicable.
            </p>
            <p>
                2.2 No part of the site, nor any content, may be copied, reproduced, aggregated, republished, uploaded, posted, publicly
                displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial
                purpose whatsoever, without our express prior written permission.
            </p>

            <h2 className="terms-subtitle">3. User Representations</h2>
            <p>3.1 By using the Site, you represent and warrant that:</p>
            <ul>
                <li>You have the legal capacity and you agree to comply with these Terms of Agreement.</li>
                <li>You are not a minor in the jurisdiction in which you reside.</li>
                <li>You will not access the Site through automated or non-human means, whether through a bot, script, or otherwise.</li>
                <li>You will not use the Site for any illegal or unauthorized purpose.</li>
                <li>Your use of the Site will not violate any applicable law or regulation.</li>
            </ul>

            <h2 className="terms-subtitle">4. User Registration</h2>
            <p>
                4.1 You may be required to register with the Site. You agree to keep your password confidential and will be responsible
                for all use of your account and password. We reserve the right to remove, reclaim, or change a username you select
                if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.
            </p>

            <h2 className="terms-subtitle">5. Prohibited Activities</h2>
            <p>
                5.1 You may not access or use the Site for any purpose other than that for which we make the Site available.
                The Site may not be used in connection with any commercial endeavors except those that are specifically
                endorsed or approved by us.
            </p>

            <h2 className="terms-subtitle">6. Modifications and Interruptions</h2>
            <p>
                6.1 We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at
                our sole discretion without notice. We also reserve the right to modify or discontinue all or part of the Services
                without notice at any time.
            </p>

            <h2 className="terms-subtitle">7. User Data Rights Under GDPR</h2>
            <p>
                7.1 As a user within the European Union, you are entitled under the General Data Protection Regulation to request
                access to, rectification, or erasure of your personal data, or restriction of processing concerning you, or to
                object to processing as well as the right to data portability.
            </p>

            <h2 className="terms-subtitle">8. Governing Law</h2>
            <p>
                8.1 These Terms shall be governed by and defined following the laws of Romania. Brand Bounce and yourself irrevocably
                consent that the courts of Romania shall have exclusive jurisdiction to resolve any dispute which may arise in
                connection with these terms.
            </p>

            <h2 className="terms-subtitle">9. Dispute Resolution</h2>
            <p>
                9.1 Any legal action of whatever nature brought by either you or us (collectively, the "Parties" and individually,
                a "Party") shall be commenced or prosecuted in the state and federal courts located in Romania, and the Parties
                hereby consent to, and waive all defenses of lack of personal jurisdiction and forum non conveniens with respect
                to venue and jurisdiction in such courts.
            </p>

            <h2 className="terms-subtitle">10. Contact Us</h2>
            <p>
                10.1 To resolve a complaint regarding the Site or to receive further information regarding use of the Site, please
                contact us at: <a href="mailto:office@brand-bounce.com">office@brand-bounce.com</a>.
            </p>
        </div>
    );
};

export default TermsPage;