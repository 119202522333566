import React, { Component } from "react";
import { FaTasks, FaChartLine, FaUsers, FaMagic, FaCalendarAlt, FaClock, FaLightbulb, FaRobot } from "react-icons/fa";
import { AiOutlinePicture, AiOutlineAudio } from "react-icons/ai";
import Aos from "aos";
import "aos/dist/aos.css";
import "../../styles/BenefitsSection.css";

class BenefitsSection extends Component {
    componentDidMount() {
        Aos.init({ duration: 1200, once: false });
    }

    render() {
        const benefitsData = [
            {
                icon: FaUsers,
                userBenefit: "Manage Multiple Brands",
                description: "Define unique voices, audiences, and strategies for each brand.",
            },
            {
                icon: FaTasks,
                userBenefit: "Effortless Content Creation",
                description: "Easily create engaging marketing materials, social media posts, email campaigns, and SEO-friendly blogs in seconds.",
            },
            {
                icon: FaChartLine,
                userBenefit: "AI-Driven Campaigns",
                description: "Optimize campaigns with actionable AI insights.",
            },
            {
                icon: FaMagic,
                userBenefit: "Step-by-Step Journeys",
                description: "Guided workflows for lead generation and conversions.",
            },
            {
                icon: AiOutlinePicture,
                userBenefit: "AI Image Generation",
                description: "Create high-quality visuals for your content in seconds.",
            },
            {
                icon: AiOutlineAudio,
                userBenefit: "Text-to-Speech",
                description: "Convert text into natural speech for a richer experience.",
            },
            {
                icon: FaCalendarAlt,
                userBenefit: "Campaign Calendar",
                description: "Plan and schedule campaigns effortlessly.",
            },
            {
                icon: FaClock,
                userBenefit: "Seamless Collaboration",
                description: "Collaborate in real-time with your team by assigning and editing content pieces effortlessly.",
            },
            {
                icon: FaRobot,
                userBenefit: "AI Chat Personal Assistant",
                description: "Get instant answers, insights, and suggestions from your personal AI assistant.",
            },
            {
                icon: FaLightbulb,
                userBenefit: "Content Ideas Generation",
                description: "Generate fresh and tailored content ideas that resonate with your audience, saving hours of brainstorming and research.",
            },
        ];

        return (
            <div className="benefits-grid-section">
                <h2 className="benefits-title">Why Choose Us?</h2>
                <p className="benefits-description">
                    Unlock the power of AI-driven tools to simplify your workflows and achieve better results.
                </p>
                <div className="benefits-grid">
                    {benefitsData.map((benefit, index) => (
                        <div className="benefit-grid-item" key={index} data-aos="fade-up" data-aos-delay={`${index * 100}`}>
                            <div className="benefit-icon-wrapper">
                                <benefit.icon className="benefit-icon" />
                                <div className="background-accent"></div>
                            </div>
                            <h3 className="benefit-title">{benefit.userBenefit}</h3>
                            <p className="benefit-description">{benefit.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default BenefitsSection;