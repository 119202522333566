import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "../styles/Nav.css";

class Nav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMenuOpen: false,
        };
    }

    toggleMenu = () => {
        this.setState({ isMenuOpen: !this.state.isMenuOpen });
    };

    closeMenu = () => {
        this.setState({ isMenuOpen: false });
    };

    render() {
        const { isMenuOpen } = this.state;

        return (
            <nav className="navbar">
                <div className="nav-logo">
                    <NavLink to="/" onClick={this.closeMenu}>
                        <img src="/logo.png" alt="YourBrand Logo" className="logo"/>
                        <img src="/logo-text.png" alt="YourBrand Logo" className="logo"/>
                    </NavLink>
                </div>

                {/* Hamburger Menu Icon for Mobile */}

                {/* Navigation Links */}
                <ul className={`nav-links ${isMenuOpen ? "mobile-show" : ""}`}>
                    <li>
                        <NavLink
                            to="/"
                            className={({isActive}) => (isActive ? "active-link" : "")}
                            onClick={this.closeMenu}
                        >
                            Home
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/features"
                            className={({isActive}) => (isActive ? "active-link" : "")}
                            onClick={this.closeMenu}
                        >
                            Features
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/pricing"
                            className={({isActive}) => (isActive ? "active-link" : "")}
                            onClick={this.closeMenu}
                        >
                            Pricing
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/contact"
                            className={({isActive}) => (isActive ? "active-link" : "")}
                            onClick={this.closeMenu}
                        >
                            Contact
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="https://app.brand-bounce.com/login"
                            className={({isActive}) => (isActive ? "active-link" : "")}
                            onClick={this.closeMenu}
                        >
                            Login
                        </NavLink>
                    </li>
                </ul>

                {/* CTA Button */}
                <div className="nav-cta">
                    <NavLink to="/pricing" className="cta-button" onClick={this.closeMenu}>
                        Get Started Now
                    </NavLink>
                </div>

                <div
                    className={`hamburger ${isMenuOpen ? "active" : ""}`}
                    onClick={this.toggleMenu}
                >
                    <span className="line"></span>
                    <span className="line"></span>
                    <span className="line"></span>
                </div>

            </nav>
        );
    }
}

export default Nav;