import React from "react";
import { NavLink } from "react-router-dom";
import "../styles/HeaderSection.css";
import screenshot from "../assets/img_1.png";

const HeaderSection = () => {
    return (
        <header className="header-section">
            {/* Animated Background */}
            <div className="animated-background"></div>

            {/* Content */}
            <div className="header-content">
                <div className="header-text">
                    <h1>Boost Productivity and Drive Sales with AI-Powered Marketing Solutions</h1>
                    <p className="subtext">
                        Transform how you create content and engage your audience. With AI-powered tools, you’ll craft high-performing content effortlessly, build impactful campaigns, and save hours of manual work. Simplify your workflows and focus on delivering value to your customers—while achieving more in less time.
                    </p>
                    <NavLink to="/pricing" className="cta-button">
                        Start Free Trial
                    </NavLink>
                </div>

                {/* Screenshot */}
                <div className="header-screenshot">
                    <img src={screenshot} alt="App Screenshot" className="screenshot" />
                </div>
            </div>
        </header>
    );
};

export default HeaderSection;