import React from "react";
import "../../styles/CaseStudiesSection.css";

const CaseStudiesSection = () => {
    const caseStudies = [
        {
            title: "Scaling Content Production for Marketing Agencies",
            challenge: "A mid-sized marketing agency struggled to produce high-quality content for multiple clients while maintaining deadlines.",
            solution: [
                "Used AI to generate blogs, social media posts, and ad copy in seconds.",
                "Defined unique brand voices and tailored audience profiles for each client.",
                "Streamlined campaign planning and scheduling using a shared calendar."
            ],
            potentialOutcome:
                "Endless possibilities for scaling content production while improving efficiency and client satisfaction.",
        },
        {
            title: "Helping Small Businesses Without Marketing Experience",
            challenge: "A small local business with no prior marketing experience struggled to build an online presence and attract customers.",
            solution: [
                "Leveraged AI-driven Journeys to create step-by-step marketing strategies for lead generation, sales, and customer engagement.",
                "Used pre-built templates for creating professional content with minimal effort.",
                "Followed guided workflows to execute campaigns effectively, including social media, email, and ads."
            ],
            potentialOutcome:
                "Unlock the potential to grow your business, attract customers, and build a lasting online presence with ease.",
        },
        {
            title: "Transforming Lead Conversion for Service-Based Businesses",
            challenge: "A service-based business struggled to convert website visitors into paying customers and generate consistent leads.",
            solution: [
                "Set up step-by-step marketing journeys for lead conversion.",
                "Created personalized email campaigns and landing page content with AI.",
                "Used text-to-speech features to create engaging audio ads."
            ],
            potentialOutcome:
                "Infinite growth opportunities with consistent lead generation and higher conversion rates.",
        },
    ];

    return (
        <div className="case-studies-section">
            <h2 className="section-title">See How We Empower Businesses Like Yours</h2>
            <p className="section-description">
                Explore examples of how our tools help marketing agencies and small businesses achieve scalable success with ease.
            </p>
            <div className="case-studies-container">
                {caseStudies.map((study, index) => (
                    <div className="case-study-card" key={index} data-aos="fade-up" data-aos-delay={`${index * 200}`}>
                        <h3 className="case-study-title">{study.title}</h3>
                        <p className="case-study-challenge"><strong>Challenge:</strong> {study.challenge}</p>
                        <div className="case-study-solution">
                            <strong>Solution:</strong>
                            <ul>
                                {study.solution.map((step, i) => (
                                    <li key={i}>{step}</li>
                                ))}
                            </ul>
                        </div>
                        <p className="case-study-outcome"><strong>Potential Outcome:</strong> {study.potentialOutcome}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CaseStudiesSection;