import React, { useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "../styles/PricingPage.css";

const PricingPage = () => {
    const [isYearly, setIsYearly] = useState(false);
    const navigate = useNavigate();

    const tiers = [
        {
            name: "Starter",
            description:
                "Ideal for freelancers, individuals, and single-brand businesses seeking a straightforward and efficient tool for content creation and basic marketing needs.",
            featuresPage: [
                "1 Brand Managed",
                "1 Active Marketing Campaign",
                "1 Active Content Campaign",
                "1 Active Email Campaign",
                "75 Image Generations Per Month",
                "150 Speech Generations Per Month",
                "1 Uncompleted Marketing Journey",
                "Unlimited Word Count",
                "Basic Collaboration Tools",
                "Audience & Brand Voice Insights",
                "Access to 80+ Templates",
                "3 Audiences Per Brand",
                "3 Voices Per Audience",
                "Calendar Planning for Streamlined Scheduling",
            ],
            monthlyCost: 59,
            extraSeatMonthlyCost: 27,
        },
        {
            name: "Growth",
            description:
                "Perfect for small businesses and growing agencies managing multiple brands and campaigns, looking for enhanced collaboration and AI-powered workflows.",
            featuresPage: [
                "Up to 5 Brands Managed",
                "Up to 3 Active Marketing Campaigns Per Brand",
                "Up to 3 Active Content Campaigns Per Brand",
                "Up to 3 Active Email Campaigns Per Brand",
                "300 Image Generations Per Month",
                "600 Speech Generations Per Month",
                "3 Uncompleted Marketing Journeys Per Brand",
                "Unlimited Word Count",
                "Enhanced Collaboration Tools",
                "Audience & Brand Voice Insights",
                "Access to 80+ Templates",
                "3 Audiences Per Brand",
                "3 Voices Per Audience",
                "Calendar Planning for Scheduling",
            ],
            monthlyCost: 99,
            extraSeatMonthlyCost: 43,
        },
        {
            name: "Advanced",
            description:
                "Designed for large agencies and enterprises handling multiple brands with advanced needs, empowering seamless collaboration and scalability.",
            featuresPage: [
                "Up to 20 Brands Managed",
                "Up to 5 Active Marketing Campaigns Per Brand",
                "Up to 5 Active Content Campaigns Per Brand",
                "Up to 5 Active Email Campaigns Per Brand",
                "600 Image Generations Per Month",
                "1200 Speech Generations Per Month",
                "5 Uncompleted Marketing Journeys Per Brand",
                "Unlimited Word Count",
                "Advanced Collaboration Tools",
                "Audience & Brand Voice Insights",
                "Access to 80+ Templates",
                "3 Audiences Per Brand",
                "3 Voices Per Audience",
                "Calendar Planning for Scheduling",
            ],
            monthlyCost: 199,
            extraSeatMonthlyCost: 60,
        },
    ];

    const handleRedirect = (planName, period) => {
        const url = `https://app.brand-bounce.com/register?planName=${planName}&period=${period}`;
        window.location.href = url; // Redirect using a full-page reload
    };

    return (
        <div className="pricing-page">
            <h1 className="pricing-title">Choose the Plan That Fits Your Needs</h1>
            <p className="pricing-description">
                Unlock the power of AI and transform how you create and manage content. <strong>Start your 3-day free
                trial</strong> today. Cancel anytime!
            </p>

            {/* Toggle Switch */}
            <div className="toggle-container">
                <span className={!isYearly ? "active-label" : ""}>Monthly</span>
                <div className="toggle-switch" onClick={() => setIsYearly(!isYearly)}>
                    <div className={`toggle-knob ${isYearly ? "yearly" : "monthly"}`}></div>
                </div>
                <span className={isYearly ? "active-label" : ""}>Yearly (15% Off)</span>
            </div>

            {/* Pricing Tiers */}
            <div className="pricing-tiers">
                {tiers.map((tier, index) => {
                    const monthlyPrice = tier.monthlyCost.toFixed(2);
                    const yearlyPrice = (tier.monthlyCost * 12 * 0.85).toFixed(2);
                    const price = isYearly ? yearlyPrice : monthlyPrice;
                    const period = isYearly ? "year" : "month";
                    const seatMonthlyPrice = tier.extraSeatMonthlyCost.toFixed(2);
                    const seatYearlyPrice = (tier.extraSeatMonthlyCost * 12 * 0.85).toFixed(2);
                    const seatPrice = isYearly ? seatYearlyPrice : seatMonthlyPrice;

                    return (
                        <div className="pricing-card" key={index}>
                            <h2 className="tier-name">{tier.name}</h2>
                            <p className="tier-description-highlight">{tier.description}</p>
                            <div className="price-container">
                                {isYearly ? (
                                    <>
                                        <p className="original-price">${(tier.monthlyCost * 12).toFixed(2)}</p>
                                        <p className="tier-price">
                                            ${yearlyPrice} <span>/ year</span>
                                        </p>
                                        <p className="discount-note">
                                            Save ${(tier.monthlyCost * 12 - yearlyPrice).toFixed(2)} per year!
                                        </p>
                                    </>
                                ) : (
                                    <p className="tier-price">
                                        ${monthlyPrice} <span>/ month</span>
                                    </p>
                                )}
                                <p className="extra-seat-price">
                                    Extra Seat: ${seatPrice} <span>/ {isYearly ? "year" : "month"}</span>
                                </p>
                            </div>
                            <div className="cta-container">
                                <button
                                    className="cta-button"
                                    onClick={() => handleRedirect(tier.name, period)}
                                >
                                    Start Free Trial
                                </button>
                            </div>
                            <ul className="tier-featuresPage">
                                {tier.featuresPage.map((feature, i) => (
                                    <li key={i} className={`feature-item ${i < 7 ? "highlighted-feature" : ""}`}>
                                        <FaCheckCircle className="feature-icon"/> {feature}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default PricingPage;