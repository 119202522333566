import React from "react";
import { NavLink } from "react-router-dom";
import "../../styles/CTASection.css";

const CTASection = () => {
    return (
        <div className="cta-section">
            <div className="cta-content">
                <h2 className="cta-title">Start Your Free 3-Day Trial Today!</h2>
                <p className="cta-description">
                    Experience the power of AI-driven tools. Simplify your workflow, save time, and grow your brand—all
                    risk-free.
                    <br />
                    <strong> Cancel anytime—no commitments!</strong>
                </p>
                <NavLink to="/pricing" className="cta-button">
                    Start Free Trial
                </NavLink>
            </div>
        </div>
    );
};

export default CTASection;