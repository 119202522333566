import React from "react";
import "../styles/ContactPage.css";
import { FaEnvelope, FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import FaqSection from "./parts/FaqSection";

const ContactPage = () => {
    return (
        <div className="contact-page">
            <h1 className="contact-title">Get in Touch</h1>
            <p className="contact-description">
                Have questions or need assistance? We're here to help! Reach out to us anytime.
            </p>
            <div className="contact-details">
                <div className="contact-item">
                    <FaEnvelope className="contact-icon" />
                    <a
                        href="mailto:office@brand-bounce.com?subject=Inquiry from Brand Bounce&body=Hi, I would like to know more about your services."
                        className="contact-link"
                    >
                       office@brand-bounce.com
                    </a>
                </div>
                <div className="social-media">
                    <p>Follow us on:</p>
                    <a href="https://facebook.com/yourpage" target="_blank" rel="noreferrer">
                        <FaFacebook className="social-icon-contact" />
                    </a>
                    <a href="https://instagram.com/yourpage" target="_blank" rel="noreferrer">
                        <FaInstagram className="social-icon-contact" />
                    </a>
                    <a href="https://twitter.com/yourpage" target="_blank" rel="noreferrer">
                        <FaTwitter className="social-icon-contact" />
                    </a>
                </div>
            </div>
            <p className="cta-text">We’d love to hear from you!</p>
            <FaqSection />
        </div>
    );
};

export default ContactPage;