import React, { Component } from "react";
import Header from "../layouts/Header";
import Hero from "./parts/Hero";
import BenefitsSection from "./parts/BenefitsSection";
import CTASection from "./parts/CTASection";
import IntegratedWithSection from "./parts/IntegratedWithSection";
import HowItWorksSection from "./parts/HowItWorksSection";
import CaseStudiesSection from "./parts/CaseStudiesSection";

class Home extends Component {
    render() {
        return (
            <div>
                <Header />
                <Hero />
                <BenefitsSection />
                <CTASection />
                <IntegratedWithSection />
                <HowItWorksSection />
                <CaseStudiesSection />
            </div>
        );
    }
}

export default Home;