import React, { useState } from "react";
import "../../styles/FaqSection.css";

const faqData = [
    {
        question: "How can I contact Brand Bounce?",
        answer: "You can reach us via email at office@brand-bounce.com. Our team will get back to you within 24-48 hours.",
    },
    {
        question: "Do I need a credit card for the free trial?",
        answer: "Yes, a credit card is required to start the free trial. You won't be charged until the trial period ends.",
    },
    {
        question: "Can I cancel my subscription anytime?",
        answer: "Absolutely! You can cancel your subscription anytime through your account settings. No questions asked.",
    },
    {
        question: "Can I upgrade or downgrade my plan?",
        answer: "Yes, you can upgrade or downgrade your plan anytime through your account settings. Changes will be reflected immediately.",
    },
    {
        question: "How do I get technical support?",
        answer: "You can email us at office@brand-bounce.com or visit our Help Center for FAQs and guides.",
    },
    {
        question: "Do you support multiple brands?",
        answer: "Yes! You can manage multiple brands and define unique audiences, voices, and campaigns for each brand.",
    },
    {
        question: "How does the free trial work?",
        answer: "You can enjoy all features included in your chosen plan for 3 days during the trial. Once the trial period ends, your selected plan will automatically begin, and you will be charged accordingly.",
    },
];

const FaqSection = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAnswer = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    return (
        <div className="faq-section">
            <h2 className="faq-title">Frequently Asked Questions</h2>
            <div className="faq-list">
                {faqData.map((faq, index) => (
                    <div
                        key={index}
                        className={`faq-item ${activeIndex === index ? "active" : ""}`}
                        onClick={() => toggleAnswer(index)}
                    >
                        <div className="faq-question">
                            {faq.question}
                            <span className="faq-icon">{activeIndex === index ? "-" : "+"}</span>
                        </div>
                        {activeIndex === index && (
                            <div className="faq-answer">{faq.answer}</div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FaqSection;