import React, { useState, useEffect } from "react";
import "../../styles/CookieConsent.css";

const CookieConsent = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Check if the user has already accepted cookies
        const cookieConsent = localStorage.getItem("cookieConsent");
        if (!cookieConsent) {
            setIsVisible(true);
        }
    }, []);

    const handleAccept = () => {
        // Store consent in localStorage
        localStorage.setItem("cookieConsent", "true");
        setIsVisible(false);
    };

    const handleDecline = () => {
        // Store decline in localStorage (optional)
        localStorage.setItem("cookieConsent", "false");
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <div className="cookie-popup">
            <div className="cookie-popup-content">
                <p>
                        We use cookies to enhance your browsing experience and analyze traffic to improve our website's performance. By clicking "Accept", you consent to the use of these cookies.{" "}
                        <a href="/cookie-policy" className="cookie-link">Learn more</a>.
                </p>
                <div className="cookie-buttons">
                    <button className="cookie-accept" onClick={handleAccept}>
                        Accept
                    </button>
                    <button className="cookie-decline" onClick={handleDecline}>
                        Decline
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CookieConsent;