import React from "react";
import "../styles/PrivacyPolicyPage.css";

const PrivacyPolicyPage = () => {
    return (
        <div className="privacy-policy-page">
            <h1 className="privacy-title">Privacy Policy</h1>
            <p className="privacy-intro">
                Brand Bounce ("we", "us", or "our") respects the privacy of our users ("user" or "you").
                This Privacy Policy explains how we collect, use, disclose, and safeguard your information
                when you visit our website <a href="https://www.brand-bounce.com">www.brand-bounce.com</a>,
                including any other media form, media channel, mobile website, or mobile application related
                or connected thereto (collectively, the "Site").
            </p>
            <p className="privacy-note">
                Please read this privacy policy carefully. If you do not agree with the terms of this privacy
                policy, please do not access the site.
            </p>
            <p className="effective-date"><strong>Effective Date: January 12, 2025</strong></p>

            <h2 className="privacy-subtitle">Information We Collect</h2>
            <h3>Personal Data</h3>
            <p>
                Personal identifiable information, such as your name, shipping address, email address,
                and telephone number, and demographic information, such as your age, gender, hometown,
                and interests, that you voluntarily give to us when you register with the Site or when you
                choose to participate in various activities related to the Site, such as online chat and message boards.
            </p>
            <h3>Derivative Data</h3>
            <p>
                Information our servers automatically collect when you access the Site, such as your IP address,
                your browser type, your operating system, your access times, and the pages you have viewed
                directly before and after accessing the Site.
            </p>

            <h2 className="privacy-subtitle">Use of Your Information</h2>
            <p>
                Having accurate information about you permits us to provide you with a smooth, efficient,
                and customized experience. Specifically, we may use information collected about you via the Site to:
            </p>
            <ul>
                <li>Create and manage your account.</li>
                <li>Email you regarding your account or order.</li>
                <li>Fulfill and manage purchases, orders, payments, and other transactions related to the Site.</li>
                <li>Increase the efficiency and operation of the Site.</li>
                <li>Monitor and analyze usage and trends to improve your experience with the Site.</li>
            </ul>

            <h2 className="privacy-subtitle">Disclosure of Your Information</h2>
            <p>We may share information we have collected about you in certain situations. Your information may be disclosed as follows:</p>
            <ul>
                <li><strong>By Law or to Protect Rights</strong></li>
                <li><strong>Third-Party Service Providers</strong></li>
                <li><strong>Marketing Communications</strong></li>
                <li><strong>Interactions with Other Users</strong></li>
                <li><strong>Online Postings</strong></li>
            </ul>

            <h2 className="privacy-subtitle">Security of Your Information</h2>
            <p>
                We use administrative, technical, and physical security measures to help protect your personal
                information. While we have taken reasonable steps to secure the personal information you provide
                to us, please be aware that despite our efforts, no security measures are perfect or impenetrable,
                and no method of data transmission can be guaranteed against any interception or other type of misuse.
            </p>

            <h2 className="privacy-subtitle">Options Regarding Your Information</h2>
            <p>
                You may at any time review or change the information in your account or terminate your account
                by contacting us using the contact information provided below.
            </p>

            <h2 className="privacy-subtitle">GDPR Compliance</h2>
            <p>
                We comply with the General Data Protection Regulation (GDPR) and ensure all data processing
                activities adhere to the principles of data protection such as data minimization, purpose limitation,
                accuracy, storage limitation, integrity, and confidentiality.
            </p>

            <h2 className="privacy-subtitle">Contact Us</h2>
            <p>
                If you have questions or comments about this Privacy Policy, please contact us at:
                <a href="mailto:office@brand-bounce.com"> office@brand-bounce.com</a>.
            </p>
        </div>
    );
};

export default PrivacyPolicyPage;