import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "../../styles/Hero.css"; // CSS for styling
import screenshot1 from "../../assets/img_2.png";
import screenshot2 from "../../assets/img_3.png";
import screenshot3 from "../../assets/img_4.png";
import screenshot4 from "../../assets/img_5.png";
import screenshot5 from "../../assets/img_6.png";
import Aos from "aos"; // Animation on scroll library
import "aos/dist/aos.css";

class HeroSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollPercentage: 0,
        };
    }

    componentDidMount() {
        // Initialize AOS animations
        Aos.init({ duration: 1200, once: false, mirror: true });

        // Attach scroll listener to animate vertical line
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        // Cleanup scroll listener
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll = () => {
        const heroSection = document.querySelector(".hero-section-wrapper");

        if (heroSection) {
            const scrollTop = window.pageYOffset; // Current scroll position
            const sectionTop = heroSection.offsetTop; // HeroSection top relative to the document
            const sectionHeight = heroSection.offsetHeight; // Total height of the HeroSection
            const viewportHeight = window.innerHeight; // Height of the visible viewport

            const visibleTop = Math.max(0, scrollTop - sectionTop); // Scrolled area within HeroSection
            const visibleHeight = Math.min(sectionHeight, viewportHeight); // Visible part of HeroSection

            const scrollPercentage = Math.min(
                (visibleTop / (sectionHeight - visibleHeight)) * 100,
                100
            ); // Calculate scroll percentage (capped at 100%)

            this.setState({ scrollPercentage });
        }
    };

    render() {
        const { scrollPercentage } = this.state;

        return (
            <div className="hero-section-wrapper">
                {/* Vertical Line */}
                <div
                    className="hero-line"
                    style={{
                        height: `${this.state.scrollPercentage}%`, // Adjust the height dynamically
                    }}
                ></div>

                {/* Hero Item 1 */}
                <section className="hero-item">
                    <div className="hero-text" data-aos="fade-right">
                        <h1>Increase Revenue with AI Marketing</h1>
                        <p className="subtext">
                            Boost sales and save valuable time with AI-suggested campaigns and step-by-step marketing
                            journeys tailored to your business goals.
                        </p>
                        <NavLink to="/pricing" className="cta-button">
                            Start Now
                        </NavLink>
                    </div>
                    <div className="hero-image" data-aos="fade-left">
                        <img src={screenshot1} alt="AI Marketing Campaigns" className="screenshot"/>
                    </div>
                </section>

                {/* Hero Item 2 */}
                <section className="hero-item reverse">
                    <div className="hero-text" data-aos="fade-left">
                        <h1>Scale Your Content Creation</h1>
                        <p className="subtext">
                            Generate fresh content ideas and create high-quality marketing, social media, email, and
                            blog content effortlessly with AI-driven tools.
                        </p>
                        <NavLink to="/pricing" className="cta-button">
                            Start Free Trial
                        </NavLink>
                    </div>
                    <div className="hero-image" data-aos="fade-right">
                        <img src={screenshot2} alt="Content Creation Tools" className="screenshot"/>
                    </div>
                </section>

                {/* Hero Item 3 */}
                <section className="hero-item">
                    <div className="hero-text" data-aos="fade-right">
                        <h1>Deliver Personalized Campaigns</h1>
                        <p className="subtext">
                            Engage your audience with tailored marketing campaigns powered by actionable AI insights.
                        </p>
                        <NavLink to="/pricing" className="cta-button">
                            Start Now
                        </NavLink>
                    </div>
                    <div className="hero-image" data-aos="fade-left">
                        <img src={screenshot3} alt="Personalized Campaigns" className="screenshot"/>
                    </div>
                </section>

                {/* Hero Item 4 */}
                <section className="hero-item reverse">
                    <div className="hero-text" data-aos="fade-left">
                        <h1>Collaborate in Real-Time</h1>
                        <p className="subtext">
                            Work seamlessly with your team to create, review, and publish content faster than ever.
                        </p>
                        <NavLink to="/pricing" className="cta-button">
                            Start Free Trial
                        </NavLink>
                    </div>
                    <div className="hero-image" data-aos="fade-right">
                        <img src={screenshot4} alt="Real-Time Collaboration" className="screenshot"/>
                    </div>
                </section>

                {/* Hero Item 5 */}
                <section className="hero-item">
                    <div className="hero-text" data-aos="fade-right">
                        <h1>Your Unique AI Assistant</h1>
                        <p className="subtext">
                            Get a personalized AI assistant tailored to your brand's voice and audience, offering
                            real-time insights, strategies, and content suggestions.
                        </p>
                        <NavLink to="/pricing" className="cta-button">
                            Start Now
                        </NavLink>
                    </div>
                    <div className="hero-image" data-aos="fade-left">
                        <img src={screenshot5} alt="AI Assistant for Your Brand" className="screenshot"/>
                    </div>
                </section>
            </div>
        );
    }
}

export default HeroSection;