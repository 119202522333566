
import React from "react";
import stripeLogo from "../../assets/stripe-logo.png"; // Replace with actual path
import googleLogo from "../../assets/google-logo.png"; // Replace with actual path
import openAILogo from "../../assets/openai-logo.png"; // Replace with actual path
import "../../styles/IntegratedWithSection.css";

const IntegratedWithSection = () => {
    return (
        <>
            <div className="visionary-section">
                <div className="visionary-content">
                    <div className="text-content" data-aos="fade-right">
                        <h2 className="visionary-title">Empowering Businesses to Succeed</h2>
                        <p className="visionary-description">
                            At <strong>Brand Bounce</strong>, we are dedicated to transforming the way businesses
                            operate. By simplifying workflows and automating complex processes, we help businesses save
                            time, reduce costs, and focus on scaling. With cutting-edge AI technology, our platform
                            drives efficiency, enhances marketing strategies, and empowers your team to achieve faster,
                            measurable results.
                        </p>
                    </div>
                    <div className="visionary-image" data-aos="fade-left">
                        <img src="/illustration.png" alt="Empowering Creators"/>
                    </div>
                </div>
            </div>

            <div className="integrated-section">
                <h2 className="integrated-title">Integrated With Industry Leaders</h2>
                <p className="integrated-description">
                    Seamlessly work with trusted platforms that power your business.
                </p>
                <div className="integrated-logos">
                    <img src={stripeLogo} alt="Stripe" className="integrated-logo"/>
                    <img src={googleLogo} alt="Google" className="integrated-logo"/>
                    <img src={openAILogo} alt="OpenAI" className="integrated-logo"/>
                </div>
            </div>
        </>
    );
};

export default IntegratedWithSection;